// Graph ql mock for disconnected development
// Generated from menuQuery.graphql in Sitecore Experiance Graph Browser further transformed
// with data.contextItem.children.forEach(c => {c.id = c.name; c.children.forEach(cc => {cc.id = cc.name});});

export const menuQueryResult = {
  contextItem: {
    children: [
      {
        id: "navigation",
        name: "navigation",
        pageTitle: {
          value: ""
        },
        menuTitle: {
          value: ""
        },
        menuPosition: {
          value: ""
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: null
        },
        url: "/navigation",
        children: []
      },
      {
        id: "6470B914B8C251E7ABAEF35F60D1A118",
        name: "main",
        pageTitle: {
          value: "Nephila Climate"
        },
        menuTitle: {
          value: "Home"
        },
        menuPosition: {
          value: "101"
        },
        isHidden: {
          value: false
        },
        multipartArticle: {
          value: true
        },
        url: "/main",
        children: [
          {
            id: "6115CB26EFC955C59D47F893AFF7EB66",
            name: "about-2",
            menuPosition: {
              value: "102"
            },
            pageTitle: {
              value: "Nephila Climate"
            },
            menuTitle: {
              value: "Home"
            },
            multipartArticle: {
              value: true
            },
            url: "/main/about-2"
          }
        ]
      },
      {
        id: "F29190FA41865700A487A4D9ED6801CC",
        name: "climate-resilience",
        pageTitle: {
          value: "Climate Resilience"
        },
        menuPosition: {
          value: "200"
        },
        menuTitle: {
          value: ""
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: true
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [],
        url: "/climate-resilience"
      },
      {
        id: "CC67707807545240AE237D583D57EB85",
        name: "renewable-energy",
        pageTitle: {
          value: "Renewable Energy"
        },
        menuPosition: {
          value: "301"
        },
        menuTitle: {
          value: ""
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: true
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [
          {
            id: "FEF3AA6416425C1DABE8CEA917165D79",
            name: "renewable-energy-2",
            menuPosition: {
              value: "302"
            },
            pageTitle: {
              value: "Renewable Energy"
            },
            multipartArticle: {
              value: true
            },
            menuTitle: {
              value: ""
            },
            url: "/renewable-energy/renewable-energy-2"
          }
        ],
        url: "/renewable-energy"
      },
      {
        id: "E66D47752CC7542AA54FEF1446AD4D3C",
        name: "agriculture",
        pageTitle: {
          value: "Agriculture"
        },
        menuPosition: {
          value: "401"
        },
        menuTitle: {
          value: ""
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: true
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [],
        url: "/agriculture"
      },
      {
        id: "432C0A8939BC576794723E850AC50A0F",
        name: "energy-markets",
        pageTitle: {
          value: "Energy Market Earnings"
        },
        menuPosition: {
          value: "501"
        },
        menuTitle: {
          value: ""
        },
        isHidden: {
          value: ""
        },
        multipartArticle: {
          value: true
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [],
        url: "/energy-markets"
      },
      {
        id: "391790CD7E625808AB8B8CC6082DBD4C",
        name: "team",
        pageTitle: {
          value: "Team"
        },
        menuPosition: {
          value: "601"
        },
        menuTitle: {
          value: ""
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: true
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [],
        url: "/team"
      },
      {
        id: "EBA63F26950A5D56A1AB9817ABA1A888",
        name: "media",
        pageTitle: {
          value: "News"
        },
        menuPosition: {
          value: "701"
        },
        menuTitle: {
          value: "News"
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#307EE1"
            }
          }
        },
        children: [
          {
            id: "blog",
            name: "blog",
            menuPosition: {
              value: "702"
            },
            pageTitle: {
              value: "Blog"
            },
            isHidden: {
              value: false
            },
            menuTitle: {
              value: "Blog"
            },
            url: "/media/blog"
          },
          {
            id: "B56A1AD2AFA8564886AA3129A787486B",
            name: "publications",
            menuPosition: {
              value: "703"
            },
            pageTitle: {
              value: "Publications"
            },
            isHidden: {
              value: false
            },
            menuTitle: {
              value: "Publications"
            },
            url: "/media/publications"
          }
        ],
        url: "/media"
      },
      {
        id: "7133C9E4E7A352DB931FCFDEA8C7EE63",
        name: "contact",
        pageTitle: {
          value: "Contact"
        },
        menuTitle: {
          value: "Office Locations"
        },
        menuPosition: {
          value: "801"
        },
        isHidden: {
          value: "1"
        },
        multipartArticle: {
          value: ""
        },
        color: {
          targetItem: {
            color: {
              value: "#F8475E"
            }
          }
        },
        url: "/contact",
        children: [
          {
            id: "C1B7AC66D1055C549DDE5ABF86AD7CD7",
            name: "contact_form",
            menuPosition: {
              value: "802"
            },
            pageTitle: {
              value: "Contact Form"
            },
            menuTitle: {
              value: "Contact Form"
            },
            url: "/contact/contact_form"
          }
        ]
      }
    ]
  }
};
