/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

const isStart = d => {
  const match = d.url.match(/\//g);
  return match && match.length == 1;
};
const getColor = d =>
  (d.color && d.color.targetItem && d.color.targetItem.color.value) ||
  "#cccccc";
function burgerMenuLinkClick() {
  const root = document.querySelector("#root");
  const burgerMenuIcon = document.querySelector(".burgerMenuIcon");
  root.classList.toggle("displayBurgerMenu");
  burgerMenuIcon.classList.toggle("change");
}

export default function BurgerMenu({ data }) {
  return (
    <div className="burger-menu climate-burger-menu">
      <div className="burger-menu-content">
        {data &&
          data.map(
            d =>
              !d.isHidden && (
                <React.Fragment key={d.id}>
                  <Link to={d.url} onClick={burgerMenuLinkClick}>
                    {d.pageTitle}
                  </Link>
                </React.Fragment>
              )
          )}
        <hr style={{ borderColor: "rgb(38, 208, 124)" }} />
        <Link to="/team" onClick={burgerMenuLinkClick}>
          Team
        </Link>
        <Link to="/media" onClick={burgerMenuLinkClick}>
          Media
        </Link>
        <Link to="/contact" onClick={burgerMenuLinkClick}>
          Contact
        </Link>
        <hr style={{ borderColor: "rgb(38, 208, 124)" }} />
        <>
          <Link to="/disclaimer" onClick={burgerMenuLinkClick}>
            Disclaimer
          </Link>
          <Link to="/disclaimer/terms-of-use" onClick={burgerMenuLinkClick}>
            Terms of Use
          </Link>
          <Link to="/disclaimer/privacy-policy" onClick={burgerMenuLinkClick}>
            Privacy Policy
          </Link>
          <Link
            to="/disclaimer/modern-slavery-act"
            onClick={burgerMenuLinkClick}
          >
            Modern Slavery Act
          </Link>
        </>
        <a href="https://www.nephila.com" className="copyright-bottom-bar">
          &#9400; Nephila Capital Limited
        </a>
      </div>
    </div>
  );
}
